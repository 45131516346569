// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup {
    z-index: 100;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    max-width: 800px;
    border-radius: 0.5em;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px 0 rgba(110, 110, 110, 0.7);
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    color: white;
    transition: all linear 0.2s;
}
.popup > h4 {
    font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/styles/popup.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,gBAAgB;IAChB,oBAAoB;IACpB,aAAa;IACb,sBAAsB;IACtB,gDAAgD;IAChD,iBAAiB;IACjB,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,kBAAkB;IAClB,YAAY;IACZ,2BAA2B;AAC/B;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".popup {\n    z-index: 100;\n    position: fixed;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 50%;\n    max-width: 800px;\n    border-radius: 0.5em;\n    padding: 20px;\n    box-sizing: border-box;\n    box-shadow: 0 4px 8px 0 rgba(110, 110, 110, 0.7);\n    margin-left: auto;\n    margin-right: auto;\n    left: 0;\n    right: 0;\n    text-align: center;\n    color: white;\n    transition: all linear 0.2s;\n}\n.popup > h4 {\n    font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
