// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu--container {
    position: fixed;
    background-color: rgba(0, 0, 0, 0);
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    transition: all linear 0.2s;
}
.menu {
    position: absolute;
    background-color: gray;
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    transition: all linear 0.2s;
    box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.4);
    /*figure out how to blur stuff behind menu*/
}
/* .menu--menu-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    display: none;
} */
.menu--menu {
    position: relative;
    font-size: 40px;
    color: white;
    margin-right: 3vw;
}
.menu--item {
    background-color: transparent;
    border: none;
    font-size: 20px;
    color: white;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid white;
    text-decoration: none;
    margin-inline: 20px;
}

.menu--menu:hover {
    cursor: pointer;
}
.menu--item:hover {
    color: gainsboro;
}

@media (min-width: 1000px) {
    .menu {
        display: none;
    }
}
@media (max-width: 600px) {
    .menu--menu {
        font-size: 35px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/menu.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kCAAkC;IAClC,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,2BAA2B;AAC/B;AACA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,UAAU;IACV,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,8CAA8C;IAC9C,2CAA2C;AAC/C;AACA;;;;;;;;GAQG;AACH;IACI,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,8BAA8B;IAC9B,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ;AACA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".menu--container {\n    position: fixed;\n    background-color: rgba(0, 0, 0, 0);\n    width: 100vw;\n    height: 100vh;\n    overflow: hidden;\n    transition: all linear 0.2s;\n}\n.menu {\n    position: absolute;\n    background-color: gray;\n    width: 50%;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    transition: all linear 0.2s;\n    box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.4);\n    /*figure out how to blur stuff behind menu*/\n}\n/* .menu--menu-container {\n    position: relative;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: end;\n    display: none;\n} */\n.menu--menu {\n    position: relative;\n    font-size: 40px;\n    color: white;\n    margin-right: 3vw;\n}\n.menu--item {\n    background-color: transparent;\n    border: none;\n    font-size: 20px;\n    color: white;\n    height: 80px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-bottom: 1px solid white;\n    text-decoration: none;\n    margin-inline: 20px;\n}\n\n.menu--menu:hover {\n    cursor: pointer;\n}\n.menu--item:hover {\n    color: gainsboro;\n}\n\n@media (min-width: 1000px) {\n    .menu {\n        display: none;\n    }\n}\n@media (max-width: 600px) {\n    .menu--menu {\n        font-size: 35px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
