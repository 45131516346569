// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.route-status-indicator{
    font-size: clamp(1em, 2vw, 1.5em);
}
.details-link {
    text-decoration: underline;
}
.details-link:hover{
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/styles/finder.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;AACrC;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,eAAe;AACnB","sourcesContent":[".route-status-indicator{\n    font-size: clamp(1em, 2vw, 1.5em);\n}\n.details-link {\n    text-decoration: underline;\n}\n.details-link:hover{\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
