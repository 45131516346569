// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact--header-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 20px;
}
.contact--quote {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
}
.contact--checkboxes {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

@media (max-width: 800px) {
    .contact--checkboxes {
        flex-direction: column;
        gap: 20px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/contact.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,SAAS;AACb;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,WAAW;AACf;;AAEA;IACI;QACI,sBAAsB;QACtB,SAAS;IACb;AACJ","sourcesContent":[".contact--header-img {\n    width: 100%;\n    height: 200px;\n    object-fit: cover;\n    border-radius: 20px;\n}\n.contact--quote {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    gap: 20px;\n}\n.contact--checkboxes {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-evenly;\n    width: 100%;\n}\n\n@media (max-width: 800px) {\n    .contact--checkboxes {\n        flex-direction: column;\n        gap: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
