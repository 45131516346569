// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    position: relative;
    color: white;
    gap: 20px;
    background-color: #d92323;
    font-size: 30px;
}
.footer--break {
    display: flex;
}
.footer--items {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    max-width: 1000px;
}
.footer--icon {
    color: white;
    font-size: 30px;
}

@media (max-width: 1000px) {
    .footer--icon {
        font-size: 30px;
    }
}

@media (max-width: 600px) {
    .footer--icon {
        font-size: 25px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/footer.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,SAAS;IACT,yBAAyB;IACzB,eAAe;AACnB;AACA;IACI,aAAa;AACjB;AACA;IACI,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,iBAAiB;AACrB;AACA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".footer {\n    z-index: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    height: 80px;\n    position: relative;\n    color: white;\n    gap: 20px;\n    background-color: #d92323;\n    font-size: 30px;\n}\n.footer--break {\n    display: flex;\n}\n.footer--items {\n    width: 80%;\n    display: flex;\n    align-items: center;\n    justify-content: space-evenly;\n    max-width: 1000px;\n}\n.footer--icon {\n    color: white;\n    font-size: 30px;\n}\n\n@media (max-width: 1000px) {\n    .footer--icon {\n        font-size: 30px;\n    }\n}\n\n@media (max-width: 600px) {\n    .footer--icon {\n        font-size: 25px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
