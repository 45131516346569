// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about--headshot {
    height: 200px;
    width: 200px;
    border-radius: 100px;
    color: gainsboro;
    border: 2px solid #d92323;
    object-fit: cover;
}
.about--info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}
.about--name {
    color: black;
    font-size: 30px;
}
.about--bio {
    color: gray;
    font-size: 20px;
}
.about--title {
    display: flex;
    align-items: center;
    gap: 10px;
}
.about--info-top {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: -moz-fit-content;
    width: fit-content;
    gap: 10px;
}

/* @media (max-width: 1000px) {
    .about--name {
        font-size: 20px;
    }
    .about--info {
        align-items: center;
        justify-content: center;
    }
    .about--bio {
        text-align: center;
        font-size: 15px;
    }
    .about--info-top {
        align-items: center;
    }
} */

@media (max-width: 600px) {
        .about--name {
        font-size: 15px;
    }
    .about--info {
        align-items: center;
        justify-content: center;
    }
    .about--bio {
        text-align: center;
        font-size: 15px;
    }
    .about--info-top {
        align-items: center;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/about.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,oBAAoB;IACpB,gBAAgB;IAChB,yBAAyB;IACzB,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,WAAW;AACf;AACA;IACI,YAAY;IACZ,eAAe;AACnB;AACA;IACI,WAAW;IACX,eAAe;AACnB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,uBAAkB;IAAlB,kBAAkB;IAClB,SAAS;AACb;;AAEA;;;;;;;;;;;;;;;GAeG;;AAEH;QACQ;QACA,eAAe;IACnB;IACA;QACI,mBAAmB;QACnB,uBAAuB;IAC3B;IACA;QACI,kBAAkB;QAClB,eAAe;IACnB;IACA;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".about--headshot {\n    height: 200px;\n    width: 200px;\n    border-radius: 100px;\n    color: gainsboro;\n    border: 2px solid #d92323;\n    object-fit: cover;\n}\n.about--info {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    width: 100%;\n}\n.about--name {\n    color: black;\n    font-size: 30px;\n}\n.about--bio {\n    color: gray;\n    font-size: 20px;\n}\n.about--title {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n}\n.about--info-top {\n    display: flex;\n    flex-direction: column;\n    align-items: start;\n    width: fit-content;\n    gap: 10px;\n}\n\n/* @media (max-width: 1000px) {\n    .about--name {\n        font-size: 20px;\n    }\n    .about--info {\n        align-items: center;\n        justify-content: center;\n    }\n    .about--bio {\n        text-align: center;\n        font-size: 15px;\n    }\n    .about--info-top {\n        align-items: center;\n    }\n} */\n\n@media (max-width: 600px) {\n        .about--name {\n        font-size: 15px;\n    }\n    .about--info {\n        align-items: center;\n        justify-content: center;\n    }\n    .about--bio {\n        text-align: center;\n        font-size: 15px;\n    }\n    .about--info-top {\n        align-items: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
