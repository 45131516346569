// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.help--question {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
}
.help--title {

}
.help--body {
    color: gray;
}
`, "",{"version":3,"sources":["webpack://./src/styles/help.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,sBAAsB;IACtB,SAAS;AACb;AACA;;AAEA;AACA;IACI,WAAW;AACf","sourcesContent":[".help--question {\n    display: flex;\n    width: 100%;\n    flex-direction: column;\n    gap: 10px;\n}\n.help--title {\n\n}\n.help--body {\n    color: gray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
